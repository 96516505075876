<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol class="col-12">
                  <CIcon name="cil-cloud-download"/>
                  {{ caption }}
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                v-if="exports !== null"
                :hover=true
                :striped=true
                :border=true
                :items="exports"
                :fields="fields"
                :pagination=false
            >
              <template #download="{item}">
                <td>
                  <CButton @click="download(item.id, item.fileName)" color="primary">
                    <CIcon name="cil-cloud-download"/>
                  </CButton>
                </td>
              </template>
              <template #dateTimeEnd="{item}">
                <td>
                  {{ item.dateTimeEnd | date }}
                </td>
              </template>
              <template #status="{item}">
                <td>
                  <CBadge :color="getStatusColor(item.status)">
                    {{getStatusLabel(item.status)}}
                  </CBadge>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import exportApi from '@/api/export'
import moment from 'moment'
import ExportHelper from '@/views/exports/ExportHelper'

const STATUSES = [
  {
    label: 'Новая',
    color: 'primary'
  },
  {
    label: 'В процессе',
    color: 'warning'
  },
  {
    label: 'Готово',
    color: 'success'
  },
  {
    label: 'Ошибка',
    color: 'danger'
  },
  {
    label: 'Загружена',
    color: 'secondary'
  }
]

export default {
  name: 'List',
  components: {},
  data() {
    return {
      exports: null,
      fields:  [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'fileName',
          label: 'Файл'
        },
        {
          key: 'dateTimeEnd',
          label: 'Дата завершения'
        },
        {
          key: 'status',
          label: 'Статус'
        },
        {
          key: 'download',
          label: 'Скачать'
        }
      ],
    }
  },
  methods: {
    getStatusLabel(id) {
      return STATUSES[id].label
    },
    getStatusColor(id) {
      return STATUSES[id].color
    },
    loadData() {
      exportApi.getLast(15).then(r => this.exports = r.data)
    },
    download(id, fileName) {
      ExportHelper.downloadFile(id, fileName)
    }
  },
  filters: {
    date(val) {
      return moment(val).format('HH:mm:ss DD.MM.YYYY')
    }
  },
  computed: {},
  created() {
    this.loadData()
  }
}
</script>
